<script setup lang="ts">
  import { MpBox, MpButton, MpCheckbox, MpModal, MpSwitch, MpText } from '@borg/ui';
  import { isArray, objectKeys, toggleElementInArray } from '@borg/utils';
  import { DynamicJobFilter, Item } from '@borg/types';
  import { isItem } from '@/utils/filter';
  import { injectSearchBar } from '../JobSearchBar/useSearchBar';

  const searchBar = injectSearchBar();
  const jobsSearchStore = useJobsSearchStore();
  const jobFilters = jobFiltersRepository.useMany();

  const isVisible = ref(false);
  const selectedFilters = reactive({ ...jobsSearchStore.filters });

  watch(isVisible, maybeResetLocalFilters, { immediate: true });

  function maybeResetLocalFilters(reset: boolean) {
    selectedFilters.static = { ...jobsSearchStore.filters.static };
    selectedFilters.dynamic = { ...jobsSearchStore.filters.dynamic };
  }

  function isSelectOptionSelected(filter: DynamicJobFilter, option: Item) {
    const value = selectedFilters.dynamic[filter.id];

    if (filter.type === 'single-select') {
      return isItem(value) && value.id === option.id;
    }

    if (filter.type === 'multi-select') {
      return isArray(value) && value.some((o) => o.id == option.id);
    }

    return false;
  }

  function toggleSelected(filter: DynamicJobFilter, option: Item) {
    const value = selectedFilters.dynamic[filter.id];

    if (filter.type === 'single-select') {
      if (isItem(value) && value.id === option.id) {
        delete selectedFilters.dynamic[filter.id];
      } else {
        selectedFilters.dynamic[filter.id] = option;
      }
    }

    if (filter.type === 'multi-select') {
      selectedFilters.dynamic[filter.id] = toggleElementInArray(
        isArray(value) ? value : [],
        option,
        (o) => o.id === option.id,
      );
    }
  }

  function onUpdateToggleable(filterId: string, value: boolean) {
    selectedFilters.dynamic[filterId] = value;
  }

  function onConfirm() {
    jobsSearchStore.replaceStaticFilter(selectedFilters.static);
    jobsSearchStore.replaceDynamicFilter(selectedFilters.dynamic);
    searchBar.search();
    isVisible.value = false;
  }

  function onClear() {
    for (const filter of objectKeys(selectedFilters.static)) {
      delete selectedFilters.static[filter];
    }
    for (const filter of objectKeys(selectedFilters.dynamic)) {
      delete selectedFilters.dynamic[filter];
    }
  }

  function onToggleNewJobs(value: boolean) {
    jobsSearchStore.setStaticFilterValue('newJobs', value);
    searchBar.search();
  }
</script>

<template>
  <div
    v-if="jobFilters"
    class="controls"
  >
    <MpSwitch
      :label="$t('JOB_FILTER.JUST_NEW_SWITCH_LABEL')"
      :model-value="jobsSearchStore.filters.static.newJobs"
      @update:model-value="onToggleNewJobs"
    />
    <MpText
      link
      semibold
      no-margin
      :class="{
        'controls__filter': true,
        'controls__filter--populated': !jobsSearchStore.isDynamicFilterClean,
      }"
      @click="isVisible = true"
    >
      {{ $t('JOB_FILTER.FILTERS_LABEL') }}
    </MpText>
  </div>
  <MpModal v-model:is-open="isVisible">
    <template #header>
      <div class="header">
        <MpText
          as="h2"
          no-margin
        >
          {{ $t('JOB_FILTER.FILTERS_LABEL') }}
        </MpText>
        <MpText
          link
          semibold
          no-margin
          @click="onClear"
        >
          {{ $t('JOB_FILTER.CLEAR_ALL') }}
        </MpText>
      </div>
    </template>
    <div class="body">
      <template
        v-for="filter in jobFilters"
        :key="filter.id"
      >
        <div
          v-if="filter.type === 'multi-select' || filter.type === 'single-select'"
          class="body__item"
        >
          <MpText
            class="list__title"
            as="h2"
          >
            {{ filter.name }}
          </MpText>
          <ul class="list">
            <MpBox
              v-for="option in filter.options"
              :key="option.id"
              class="list__option"
              as="li"
              ripple
              @click="toggleSelected(filter, option)"
            >
              <MpText
                type="body1"
                no-margin
              >
                {{ option.name }}
              </MpText>
              <MpCheckbox :model-value="isSelectOptionSelected(filter, option)" />
            </MpBox>
          </ul>
        </div>
        <div
          v-if="filter.type === 'toggle'"
          class="body__item"
        >
          <div class="list__option">
            <MpSwitch
              :key="filter.id"
              :model-value="!!selectedFilters.dynamic[filter.id]"
              :label="filter.name"
              @update:model-value="onUpdateToggleable(filter.id, $event)"
            />
          </div>
        </div>
      </template>
      <MpBox
        surface="60"
        class="body__controls"
      >
        <MpButton
          text="Odustani"
          variant="secondary"
          is-full-width
          @click="isVisible = false"
        />
        <MpButton
          text="Filtriraj"
          is-full-width
          @click="onConfirm"
        />
      </MpBox>
    </div>
  </MpModal>
</template>

<style scoped lang="scss">
  .controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: var(--mp-space-40);

    @include screen-xl {
      display: none;
    }

    &__filter {
      margin-right: 1em;
      color: var(--mp-color-primary-default);
      position: relative;

      &--populated {
        &::after {
          position: absolute;
          top: 0;
          right: -8px;
          display: block;
          content: '';
          height: 5px;
          width: 5px;
          border-radius: var(--mp-radius-full);
          background-color: var(--mp-color-secondary-default);
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--mp-color-grey-200);
    padding: var(--mp-space-50) 0;
    width: 100%;

    @include dark-mode {
      border-color: var(--mp-color-grey-700);
    }
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-50);
    margin-bottom: 80px;

    &__item:not(:last-child) {
      padding: var(--mp-space-50) 0;
      border-bottom: 1px solid var(--mp-color-grey-200);

      @include dark-mode {
        border-color: var(--mp-color-grey-700);
      }
    }

    &__controls {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      gap: var(--mp-space-30);
      padding: var(--mp-space-50);
      border-radius: 15px;
    }
  }

  .list {
    &__title {
      // padding: 0;
    }

    &__option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: var(--mp-space-30) 0;
      cursor: pointer;
    }
  }
</style>
